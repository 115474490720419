<template>
    <div class="hero">
        <div class="kontejner">
            <div class="dan">
                <div class="datum">Petak 19.5</div>
                <div class="vreme" style="font-weight: 700; padding: 10px;">10h Svecano otvaranje</div>
                <div class="predavanja">
                    <div class="predavanje1"><p class="naslov">Kako se izdvojiti na razgovoru za posao? ’’CV and tips and tricks’’ za uspešan intervju.</p><p class="predavac">Staša Lopušina i Stevanija Matejić Ivanović - Yettel</p><p class="opis"></p></div>
                    <div class="predavanje2"><p class="naslov">Upravljanje vremenom</p><p class="predavac">Dunja Đorđević - NIS</p><p class="opis">Upravljanje vremenom“ (Znamo li zaista da upravljamo našim vremenom? Koje su tehnike i alati koji nam mogu pomoći u ovoj veštini? – više na samoj radionici).</p></div>
                </div>
            </div>

  
            
            <div class="dan">
                <div class="datum">Subota 20.5</div>
                <div class="vreme">10:00-19:00h</div>
                <div class="predavanja">
                    <div class="predavanje1"><p class="naslov">Autentičnost: Tvoj glas, tvoj put!</p><p class="predavac">Uroš Savurdić</p><p class="opis">Potrebu da se izrazimo pred svetom na sebi svojstven način ima svako ljudsko biće. Često se dešava da zaboravimo svoj glas i svoje autentično ja pa se izgubimo na putu ka sopstvenim ciljevima. 

Na ovom treningu učesnici će imati priliku da kroz interakrivne zadatke i diskusije saznaju više o konceptu autentičnosti, upoznaju bolje sebe i odluče o potrebnim koracima ka izgradnji svog glasa u svetu koji ih okružuje.</p></div>
                    <div class="predavanje2"><p class="naslov">Umetnost nenasilne komunikacije</p><p class="predavac">Marija Milanović</p><p class="opis">Trening nenasilne komunikacije predstavlja proces učenja veština koje vam omogućavaju da se izrazite jasno i direktno, bez upotrebe agresivnog jezika. Naučićete kako da prepoznate kada neka komunikacija postaje nasilna i kako da se sa tim nosite na način koji vodi ka rešavanju problema, umesto eskalaciji konflikta. Govorićemo i o tome kako da se nosite sa sopstvenim emocijama i budete empatični prema drugima, što će vam pomoći da ostvarite bolje i održivije odnose. Kroz praktične vežbe i primere iz stvarnog života, unapredićete svoje veštine komunikacije i biti uspešniji u svom ličnom i profesionalnom životu.</p></div>
                </div>
            </div>

  
            
            <div class="dan">
                <div class="datum">Nedelja 21.5</div>
                <div class="vreme">10:00-19:00h</div>
                <div class="predavanja">
                    <div class="predavanje1"><p class="naslov">Imaš strah od ambicioznih ciljeva? Let's change that</p><p class="predavac">Nevena Tomić i Ermin Mujanović   </p><p class="opis">Postavljanje ciljeva je osnovni deo ličnog i profesionalnog razvoja i ključan je za napredak u bilo kojoj oblasti života. Na treningu ćemo istražiti kako različiti načini razmišljanja mogu uticati na ovaj proces i dovesti do prihvatanja izazova, efikasnog suočavanja sa preprekama i otkrivanja mogućnosti za učenje i razvoj. Sesija će sadržati aktivnosti za efikasno postavljanje ciljeva, kreiranje strukture za postizanje ciljeva i suočavanje sa zastojima i strahom od neuspeha.</p></div>
                    <div class="predavanje2"><p class="naslov">Liderstvo</p><p class="predavac">Simona Šutić</p><p class="opis">Otključajte svoj potencijal kao budući lider na ovom treningu mekih veština! Razvijanje snažnih veština liderstva ključno je za uspeh kako u akademskim tako i u profesionalnim poduhvatima. Ovaj trening se fokusira na poboljšanje veština komunikacije, timskog rada, rešavanja problema i kritičkog razmišljanja - sve što je ključno za efektivno liderstvo. Uz interaktivnu sesiju, imaćete priliku da čujete nešto više o motivaciji i inspiraciji, izgradite jake odnose i vodite s poverenjem. Napravite prvi korak ka ostvarenju svog liderskog potencijala!</p></div>
                </div>
            </div>

  
            




        </div>
    </div>
</template>

<style scoped>

    .hero{
            background-image: url("../assets/home_hero_bg.png");
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center center;
            background-attachment: fixed;
            height: 100%;
            left: 0;
            right: 0;
            font-size: 17px;        
            padding-top: 10%;
            padding-bottom: 10%;
        }

    @media(max-width: 768px){
        .hero{
            background: #11998e;
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center center;
            background-attachment: fixed;
            height: 100%;
            left: 0;
            right: 0;
            font-size: 17px;        
            padding-top: 10%;
            padding-bottom: 10%;
        }
    }

    .kontejner{

        display: block;
        margin-left: 15px;
        margin-right: 15px;
    }

    .dan {
        margin-bottom: 40px;
        max-width: 800px;
        height: 100%;
        border: 1px solid black;
        border-radius: 20px;
        overflow: hidden;
        margin-left: 10px;
        margin-right: 10px;
        box-shadow: 5px 5px 4px rgba(0, 0, 0, 3);
        margin-left: auto;
        margin-right: auto;
    }

    .datum {
        background-color: #24888E;
        font-weight: 700;
        padding: 5px;
    }
    
    .vreme {
        padding: 5px;
        background-color: #C8EFE1;
    }

    .predavanja {
        display: flex;
        justify-content: space-around;
    }

    .predavanja div {
        justify-content: center;
        width: 50%;
        padding: 10px 20px;
    }
    

    .predavanje1{
        background-color: #11998E;
    }

    .predavanje2{
        background-color: #38EF7D;
    }

    .naslov{
        font-weight: 700;
        margin-top: 0px;
        margin-bottom: 0px;
        font-size: 20px;
        line-height: 1;
    }

    .predavac{
        font-weight: 600;
        margin-top: 5px;
        margin-bottom: 20px;
    }
    .opis{
        text-align: justify;
    }

@media (max-width: 1100px) {
    .hero {
        padding-top: 120px;
    }
}

@media (max-width: 768px) {
    .hero {
        font-size: 14px;
    }

    .naslov{
        font-size: 16px;
    }
}

@media (max-width: 479px) { 

    .naslov{
        font-size: 14px;
    }

    .hero{
        font-size: 12px;
    }

    .predavanja div {
        padding: 5px 10px;
    }
}

</style>