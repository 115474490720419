<template>
    <div id="pozadina">
        <div class="kontakt container">
            
            <div class="cardWrapper">
                <Card style="position: absolute; z-index: 997;" :photoUrl="imgDjole" :text="img">
                    <p style="font-size: 20px; font-weight: bold;">Đorđe Mitić</p>
                    <p>Glavni organizator</p>
                    <p style="width: 100%;">glavni.organizator@softskillsacademy.rs</p>
                </Card> 
                <div class="info">
                    <p style="font-size: 20px; font-weight: bold;">Đorđe Mitić</p>
                    <p>Glavni organizator</p>
                    <p style="width: 100%;">glavni.organizator@softskillsacademy.rs</p>
                </div>
            </div>

            <div class="cardWrapper">
                <Card style="position: absolute; z-index: 997;" :photoUrl="imgJovana" :text="img">
                    <p style="font-size: 20px; font-weight: bold;">Jovana Krmpotić</p>
                    <p>Koordinator za publikacije</p>
                    <p style="width: 100%;">publikacije@softskillsacademy.rs</p>
                </Card>
                <div class="info">
                    <p style="font-size: 20px; font-weight: bold;">Jovana Krmpotić</p>
                    <p>Koordinator za publikacije</p>
                    <p style="width: 100%;">publikacije@softskillsacademy.rs</p>
                </div>
            </div>

            <div class="cardWrapper">
                <Card style="position: absolute; z-index: 997;" :photoUrl="imgNikola" :text="img">
                    <p style="font-size: 20px; font-weight: bold;">Nikola Milković</p>
                    <p>Koordinator za odnose sa javnošću</p>
                    <p style="width: 100%;">pr@softskillsacademy.rs</p>
                </Card>
                <div class="info">
                    <p style="font-size: 20px; font-weight: bold;">Nikola Milković</p>
                    <p>Koordinator za odnose sa javnošću</p>
                    <p style="width: 100%;">pr@softskillsacademy.rs</p>
                </div>
            </div>

            <div class="cardWrapper">
                <Card style="position: absolute; z-index: 997;" :photoUrl="imgLana" :text="img">
                    <p style="font-size: 20px; font-weight: bold;">Lana Jovičić</p>
                    <p>Koordinator za logistiku</p>
                    <p style="width: 100%;">logistika@softskillsacademy.rs</p>
                </Card>
                <div class="info">
                    <p style="font-size: 20px; font-weight: bold;">Lana Jovičić</p>
                    <p>Koordinator za logistiku</p>
                    <p style="width: 100%;">logistika@softskillsacademy.rs</p>
                </div>
            </div>

            <div class="cardWrapper">
                <Card style="position: absolute; z-index: 997;" :photoUrl="imgNata" :text="img">
                    <p style="font-size: 20px; font-weight: bold;">Natalija Nikolić</p>
                    <p>Koordinator za promocije</p>
                    <p style="width: 100%;">promocije@softskillsacademy.rs</p>
                </Card>
                <div class="info">
                    <p style="font-size: 20px; font-weight: bold;">Natalija Nikolić</p>
                    <p>Koordinator za promocije</p>
                    <p style="width: 100%;">promocije@softskillsacademy.rs</p>
                </div>
            </div>

            <div class="cardWrapper">
                <Card style="position: absolute; z-index: 997;" :photoUrl="imgMateja" :text="img">
                    <p style="font-size: 20px; font-weight: bold;">Mateja Orlić</p>
                    <p>Koordinator za odnose sa privredom</p>
                    <p style="width: 100%;">fr@softskillsacademy.rs</p>
                </Card>
                <div class="info">
                    <p style="font-size: 20px; font-weight: bold;">Mateja Orlić</p>
                    <p>Koordinator za odnose sa privredom</p>
                    <p style="width: 100%;">fr@softskillsacademy.rs</p>
                </div>
            </div>

            <div class="cardWrapper">
                <Card style="position: absolute; z-index: 997;" :photoUrl="imgUros" :text="img">
                    <p style="font-size: 20px; font-weight: bold;">Uroš Lalić</p>
                    <p>IT koordinator</p>
                    <p style="width: 100%;">it@softskillsacademy.rs</p>
                </Card>
                <div class="info">
                    <p style="font-size: 20px; font-weight: bold;">Uroš Lalić</p>
                    <p>IT koordinator</p>
                    <p style="width: 100%;">it@softskillsacademy.rs</p>
                </div>
            </div>









           <!-- <div class="cardWrapper">
                <Card style="position: absolute; z-index: 999;" :photoUrl="img" :text="img"/> 
                <div class="info">
                    <p style="font-size: 20px; font-weight: bold;">Teodora Vucurevic</p>
                    <p>Glavni organizator</p>
                    <p style="width: 100%;">glavni_organizator@softskillsacademy.rs</p>
                </div>
            </div> -->


            <!-- <div class="cardWrapper2">
                <Card class="img2" style="position: absolute; z-index: 997;" :photoUrl="img" :text="img"/> 
                <div class="info2">
                    <p style="font-size: 20px; font-weight: bold;">Teodora Vucurevic</p>
                    <p>Glavni organizator</p>
                    <p style="width: 100%;">glavni_organizator@softskillsacademy.rs</p>
                </div>
            </div> -->

            <!-- <div class="cardWrapper">
                <Card style="position: absolute; z-index: 999;" :photoUrl="img" :text="img"/> 
                <div class="info">
                    sdassdad
                </div>
            </div> -->


        </div>

    </div>

</template>

<script>
import Card from "../components/ContactCard.vue"

export default{
    components: {Card},
    data (){
        return{
            img: require("../assets/oNama_bg.png"),
            imgDjole: require("../assets/kontakt/Djole.png"),
            imgJovana: require("../assets/kontakt/Jovana.png"),
            imgNikola: require("../assets/kontakt/Nikola.png"),
            imgLana: require("../assets/kontakt/Lana.png"),
            imgNata: require("../assets/kontakt/Nata.png"),
            imgMateja: require("../assets/kontakt/Mateja.png"),
            imgUros: require("../assets/kontakt/Uros.png"),

        }
    }

}

</script>



<style>
.kontakt{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    min-height: 700px;
    padding-top: 9%;
    padding-bottom: 80px;
}

/*  */

.info {
    background-color: #38EF7D;
    opacity: 0.9;
    position: absolute;
    transition: transform 0.5s ease;
    height: 280px;
    width: 180px;
    overflow-wrap: break-word;
    padding: 10px;
    z-index: 1001;
    transform: translate(0px, 300px);
}

.cardWrapper {
    position: relative;
    height: 280px;
    width: 200px;
    overflow: hidden;
    margin: 20px 30px;
    
}

.cardWrapper:hover .info{
    transform: translate(0px, 0px);
}

#pozadina{
    width: 100%;
        background-image: url("../assets/home_hero_bg.png");
        background-position: center center;
        background-attachment: fixed;
        background-repeat: no-repeat;
        background-size: cover;
}

@media(max-width: 768px){
    #pozadina{
        background: #11998e;
    }
}


@media (max-width: 992px) {
    .cardWrapper {
        pointer-events: none;
    }
}

@media (max-width: 479px){
    .cardWrapper {
        margin-top: 10px;
        margin-bottom: 10px;
    }
}

@media (max-width: 350px){
    .cardWrapper { 
        margin: 5px 5px;
    }
}





/* .info {

    background-color: #38EF7D;
    border: 2px solid white;
    position: absolute;
    transition: transform 0.3s ease;
    height: 280px;
    width: 180px;
    overflow-wrap: break-word;
    padding: 10px;
}


.cardWrapper {
    position: relative;
    height: 300px;
    width: 200px;
}

.cardWrapper:hover .info{
    transform: translate(200px, 0px);

}

.info2 {

    background-color: #38EF7D;
    border: 2px solid white;
    position: absolute;
    transition: transform 0.3s ease;
    height: 280px;
    width: 180px;
    overflow-wrap: break-word;
    padding: 10px;
    z-index: 996;
}

.img2{
    transition: transform 0.6s ease;
}

.cardWrapper2 {
    position: relative;
    height: 300px;
    width: 200px;
    overflow: hidden;
}

.cardWrapper2:hover .img2{
    transform: translate(0px, -302px);

} */
</style>