<template>
  <div class="main-div"> <!-- This is to fix footer -->

    <nav v-if="!mobileHorizontalView" :class="{ 'menuSticky': sticky }" class="navMenu">
      <div class="container" id="menu">
        <router-link to="/">
          <img src="./assets/logo.png" alt="">
        </router-link>

        <div>
          <router-link to="/">Početna</router-link>
          <router-link :to="{ name: 'oProjektu' }">O projektu</router-link>
          <!--<router-link :to="{ name: 'raspored' }">Raspored</router-link>-->
          <!--<router-link :to="{ name: 'treneri' }">Treneri</router-link>-->
          <!-- <router-link to="treneri">Galerija</router-link>
          <router-link to="treneri">Sponzori</router-link> Galerija? -->
          <router-link :to="{ name: 'kontakt' }">Kontakt</router-link>
        </div>
        <PrijaviSe style="margin-right: 25px; box-shadow: 1px 2px black;">Prijavi se</PrijaviSe>
      </div>
    </nav>

    <!-- Mobile version -->
    <div class="navMenu" v-if="mobileHorizontalView">
      <img @click="toggleMenu" v-if="!menuOpened" class="minimizedMenu" style="float: left; margin-left: 5px;"
        src="./assets/menuIcon.svg" alt="">
      <nav v-if="menuOpened" class="navMenu menuMobile">
        <div @click.self="toggleMenu" style="background-color: rgba(255, 255, 255, 0.85); width: 100%;" v-if="menuOpened">
          <img style="height: 120px; margin-top: 20px;" src="./assets/mainLogo.png" alt="s">
          <router-link class="mobileLinks" to="/">Početna</router-link>
          <router-link class="mobileLinks" :to="{name: 'oProjektu'}">O projektu</router-link>
          <!-- <router-link class="mobileLinks" to="treneri">Treneri</router-link> TODO dodati raspored
          <router-link class="mobileLinks" to="treneri">Galerija</router-link>-->
          <!--<router-link class="mobileLinks" :to="{name: 'raspored'}">Raspored</router-link>-->
          <!--<router-link class="mobileLinks" :to="{name: 'treneri'}">Treneri</router-link>-->
          <router-link class="mobileLinks" :to="{ name: 'kontakt' }">Kontakt</router-link>
          <PrijaviSe style="box-shadow: 1px 2px black; display: block; margin: 20px auto 0px auto; width: 250px;">Prijavi
            se</PrijaviSe>
        </div>

      </nav>
    </div>


    <router-view />


    <div class="footer">
      <a target="_blank" href="http://eestec.etf.bg.ac.rs/">
        <img :style="{ width: mobile ? '80px' : {}, margin: mobile ? '0px' : '25px', height: mobile ? '100%' : {} }"
          style="margin-right: 70px;" src="./assets/IstekLogo.png" alt="">
      </a>
      <div class="socNetworks">
        <a target="_blank" href="https://www.facebook.com/ssa.belgrade">
          <img :style="{ width: mobile ? '20px' : {} }" src="./assets/facebook.png" alt="">
        </a>
        <a target="_blank" href="https://www.instagram.com/ssa_belgrade/">
          <img :style="{ width: mobile ? '20px' : {} }" src="./assets/instagram.png" alt="">
        </a>
        <a target="_blank" href="https://www.linkedin.com/company/ssa-belgrade/">
          <img :style="{ width: mobile ? '20px' : {} }" src="./assets/linkedin.png" alt="">
        </a>
      </div>
    </div>

  </div>
</template>



<script>
import PrijaviSe from './components/PrijaviSe.vue'
// TODO da prosledjujem mobileView TabletWiew i ostalo kroz props, da ne bi u svakom fajlu pravio zasebno

export default {
  components: { PrijaviSe },
  data() {
    return {
      sticky: false,
      mobileHorizontalView: false,
      menuOpened: false,
      mobile: false
    }
  },
  methods: {
    toggleMenu() {
      this.menuOpened = !this.menuOpened
    },

    handleScroll(event) {
      var currentScrollPosition = window.scrollY

      if (currentScrollPosition < this.scrollPosition) {
        //console.log("Scrolling up");
        this.sticky = false
        this.$refs.nav
        //your desire logic 
      }
      else {
        this.sticky = true
        //console.log("Scrolling down");
        //your desire logic 

      }

      //console.log(this.sticky)
      this.scrollPosition = window.scrollY
    },
    handleView() {
      this.tabletView = window.innerWidth <= 991,
        this.mobileHorizontalView = window.innerWidth <= 767
      this.mobile = window.innerWidth <= 478
    }
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll),
      window.addEventListener('resize', this.handleView) //TODO mozda ovo mogu da prebacim u created posle pozivanja fje
  },
  created() {
    this.handleView() // required when first is loaded, ustvari mozda mi samo ovo treba, 
    //  jer niko ko je na desktopu nece menjati velicinu ekrana, ali moze da ostane cisto za svaki slucaj
  }

}
</script>



<style>
#app {
  /* font-family: Avenir, Helvetica, Arial, sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  /* color: #2c3e50; */
}

nav {
  transition: 1.2s;
}

.navMenu {
  position: fixed;
  width: 100%;
  z-index: 999;
}

#menu {
  background-color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 50px;
  padding-bottom: 5px;
  margin-top: 15px;
}

.menuSticky {
  margin-top: -155px;
}

.menuMobile {
  height: 100%;
  display: flex;
  justify-content: center;
}

.mobileLinks {
  display: block;
  width: 200px;
  margin: 15px auto;
  font-weight: bold;
}

nav a {
  font-weight: 600;
  font-size: 22px;
  text-decoration: none;
  color: #1d1d1d;
  margin-left: 25px;
  transition: color 0.3s;
}

nav a:hover {
  color: green;
}

nav a.router-link-exact-active {
  color: #11998E;
}

.main-div {
  min-height: 100vh;
}

.footer {
  display: flex;
  padding: 20px 0px;
  justify-content: center;
  align-items: center;
  background-color: #38EF7D;
  position: sticky;
  top: 100%;
}


.socNetworks img {
  display: block;
  margin-top: 20px;
}


@media (max-width: 991px) {
  nav a {
    font-size: 18px;
  }
}

@media (max-width: 768px) {}

@media (max-width: 479px) {
  .socNetworks img {
    display: inline;
    margin: 0px 5px;
  }

  .footer {
    padding: 20px 0px;
  }
}
</style>

