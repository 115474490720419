<template>
   <!-- <img src="../assets/home_hero_bg.png" alt=""> -->
   
   <div id="background">
    <div id="preFormText" style="text-align: left; margin-bottom: 30px;">
       <p style="font-weight: 400"> „Soft Skills Academy – Unapredi svoje sposobnosti!“ je besplatan seminar ličnih i profesionalnih veština namenjen studentima svih fakulteta u Beogradu. Cilj projekta je da ambicioznim studentima pruži šansu da poboljšaju neophodne profesionalne veštine koje nemamo priliku naučiti kroz formalno obrazovanje.</p>
        <p style="margin-bottom: 0px; font-weight: bold; font-size: 23px;">Kako je Akademija nastala?</p>
        <p style="font-weight: 400">U 2010. godini Udruženje studenata elektrotehnike Evrope – Lokalni komitet Beograd je došlo na ideju da kroz niz interaktivnih treninga omogući studentima da se bolje opreme za uspešnu karijeru. Tokom godina, razni komiteti našeg Udruženja su prepoznali važnost veština koje Soft Skills Academy pruža, te se danas, 15 godina kasnije, ovaj naš projekat održava u čak 18 evropskih gradova! </p>
        <p style="font-weight: 400">Akademija se sada organizuje šesnaesti put zaredom od strane Evropskog udruženja studenata elektrotehnike – EESTEC.</p>
        <p style="margin-bottom: 0px; font-weight: bold; font-size: 23px;">Kako će izgledati seminar i šta možeš da očekuješ od Akademije?</p>
        <p style="font-weight: 400">Seminar je besplatan i otvoren za sve zainteresovane studente svih fakulteta u Beogradu, a najbolji kandidati biće izabrani da učestvuju na treninzima. Tokom trodnevnog seminara 35 odabranih učesnika će biti u prilici da prisustvuje edukativnim i interaktivnim treninzima. </p>
        <p style="font-weight: 400">Treninzi na seminaru su posvećeni unapređenju ličnih i profesionalnih veština učesnika, razvoju polaznika kao pojedinaca, a teme treninga su osmišljene tako da odgovore na profesionalne izazove kojima će polaznici u budućnosti biti izloženi. Seminar nudi treninge sa temama: vremenskog organizovanja, prezentacionih i komunikacionih veština, vođenja projekata, rešavanja konflikata, liderskih veština, rada u timu i mnoge druge srodne teme.</p>
        <p style="font-weight: 400">Treninge će držаti predstаvnici konsultаntskih kućа i kompаnijа sа kojimа sаrаđujemo, kao i sertifikovаni domaći i internаcionаlni EESTEC treneri.</p>
        <p style="font-weight: 400">Po završetku Akademije učesnicima će biti dodeljeni sertifikati potpisani od strane organizatora.</p>
        <p style="margin-bottom: 0px; font-weight: bold; font-size: 23px;">Kada se održava Akademija?</p>
        <p style="font-weight: 400">Seminar se ove godine održava uživo u periodu od 25 do 27. aprila.</p>
        <p style="font-weight: 400">Svi zainteresovani se mogu prijaviti putem sajta softskillsacademy.rs. Za sve dodatne informacije možete posetiti naše društvene mreže.</p>
        <p style="margin-bottom: 0px; font-weight: bold; font-size: 23px;">Nadamo se što većem odzivu, vidimo se na Akademiji!</p>
    </div>
</div>
   <!--<div class="hero">
        <div class="container" style="padding-top: 10%; padding-bottom: 5%; color:white">
            <h2 style="margin-top: 0px;">Šta je Soft Skills Academy?</h2>
            <p>„Soft Skills Academy – Unapredi svoje sposobnosti!“ je besplatan seminar ličnih i profesionalnih veština namenjen studentima svih fakulteta u Beogradu. Cilj projekta je da ambicioznim studentima pruži šansu da poboljšaju neophodne profesionalne veštine koje nemamo priliku naučiti kroz formalno obrazovanje.</p>
            <h2>Kako je Akademija nastala?</h2>
            <p>U 2010. godini Udruženje studenata elektrotehnike Evrope – Lokalni komitet Beograd je došlo na ideju da kroz niz interaktivnih treninga omogući studentima da se bolje opreme za uspešnu karijeru. Tokom godina, razni komiteti našeg Udruženja su prepoznali važnost veština koje Soft Skills Academy pruža, te se danas, 15 godina kasnije, ovaj naš projekat održava u čak 18 evropskih gradova! </p>
            <p>Akademija se sada organizuje šesnaesti put zaredom od strane Evropskog udruženja studenata elektrotehnike – EESTEC.</p>
            <h2>Kako će izgledati seminar i šta možeš da očekuješ od Akademije?</h2>
            <p>Seminar je besplatan i otvoren za sve zainteresovane studente svih fakulteta u Beogradu, a najbolji kandidati biće izabrani da učestvuju na treninzima. Tokom trodnevnog seminara 35 odabranih učesnika će biti u prilici da prisustvuje edukativnim i interaktivnim treninzima. </p>
            <p>Treninzi na seminaru su posvećeni unapređenju ličnih i profesionalnih veština učesnika, razvoju polaznika kao pojedinaca, a teme treninga su osmišljene tako da odgovore na profesionalne izazove kojima će polaznici u budućnosti biti izloženi. Seminar nudi treninge sa temama: vremenskog organizovanja, prezentacionih i komunikacionih veština, vođenja projekata, rešavanja konflikata, liderskih veština, rada u timu i mnoge druge srodne teme.</p>
            <p>Treninge će držаti predstаvnici konsultаntskih kućа i kompаnijа sа kojimа sаrаđujemo, kao i sertifikovаni domaći i internаcionаlni EESTEC treneri.</p>
            <p>Po završetku Akademije učesnicima će biti dodeljeni sertifikati potpisani od strane organizatora.</p>
            <h2>Kada se održava Akademija?</h2>
            <p>Seminar se ove godine održava uživo u periodu od 25 do 27. aprila.</p>
            <p>Svi zainteresovani se mogu prijaviti putem sajta softskillsacademy.rs. Za sve dodatne informacije možete posetiti naše društvene mreže.</p>
            <p style="margin-bottom: 0px; margin-top: 50px; font-weight: bold; font-size: 23px;">Nadamo se što većem odzivu, vidimo se na Akademiji!</p>
        </div>
    </div>-->
</template>


<style scoped>
    /* .hero{
        background-image: url("../assets/home_hero_bg.png");
        width: 100%;
        height: 100vh;
        position: fixed;
        background-repeat: no-repeat;
        background-size: cover;
        padding: 80px 0px;
    } */


    .hero{
        background-image: url("../assets/home_hero_bg.png");
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center center;
        background-attachment: fixed;
        height: 100%;
        left: 0;
        right: 0;
    }

@media (max-width: 992px){
    div {
        padding-top: 50px;
    }
}

@media (min-width: 768px) {
  #background {
    width: 100%;
    background-image: url("../assets/home_hero_bg.png");
    background-position: center center;
    background-attachment: fixed;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    flex-direction: column; /* Stavlja elemente jedan ispod drugog */
    align-items: center; /* Centriranje po širini */
    justify-content: center; /* Centriranje po visini, ako treba */
  }
  #preFormText {
    width: 50%;
    background: white;
    text-align: center;
    padding: 20px;
    margin-top: 150px;
    border-radius: 10px;
  }
  #preFormText p,
  #preFormText div {
    display: block;
    font-size: 1em;
    font-weight: bold;
    color: #11998e;
    letter-spacing: 1px;
    margin: 25px 0 15px;
    padding-left: 6px;
    font-weight: bold;
  }

  #preFormText,
  form {
    width: 60%; /* Oba elementa imaju istu širinu */
    box-sizing: border-box; /* Osigurava da padding ne utiče na širinu */
  }

  #posaljiPrijavu {
    margin-bottom: 40px;
  }
}

@media (max-width: 768px) {
  #background {
    width: 100%;
    background-color: #11998e;
    display: flex;
    flex-direction: column; /* Stavlja elemente jedan ispod drugog */
    align-items: center; /* Centriranje po širini */
    justify-content: center; /* Centriranje po visini, ako treba */
    padding: 10px 0; /* Razmak oko sadržaja */
  }
  #preFormText {
    width: 50%;
    background: white;
    text-align: center;
    font-weight: bold;
    color: #11998e;
    padding: 20px;
    margin-top: 60px;
    border-radius: 10px;
  }
  #preFormText p {
    color: #11998e;
    display: block;
    font-size: 1em;
    letter-spacing: 1px;
    margin-top: 25px;
    padding-left: 6px;
    font-weight: bold;
  }
  #preFormText,
  form {
    width: 80%; /* Oba elementa imaju istu širinu */
    box-sizing: border-box; /* Osigurava da padding ne utiče na širinu */
  }

  #posaljiPrijavu {
    margin-bottom: 5px;
  }
}

    /* img{
        width: 100%;
        height: 100vh;
        position: fixed;
    } */

</style>