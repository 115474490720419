import { createRouter, createWebHashHistory, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import About from '../views/About.vue'
import Kontakt from '../views/Kontakt.vue'
import SingleKontakt from '../views/SingleKontakt.vue'
import OProjektu from '../views/OProjektu.vue'
import PrijavaForma from '@/views/PrijavaForma.vue'
import Raspored from '@/views/Raspored.vue'
import Treneri from '@/views/Treneri.vue'

const routes = [
  {
    path: '/',
    name: 'home',// "ime"
    component: Home // koja ce se komponenta koristiti kad se dodje na ovu lokaciju
  },
  {
    path: '/about',
    name: 'about',
    component: About
  },
  {
    path: '/kontakt',
    name: 'kontakt',
    component: Kontakt
  },
  {
    path: '/kontakt/:ime', //ovo cu cisto da probam ovo sa parametrima
    name: 'singleKontakt',
    component: SingleKontakt
  },
  {
    path: '/o_projektu',
    name: 'oProjektu',
    component: OProjektu
  },
  {
    path: '/forma',
    name: 'forma',
    component: PrijavaForma
  },
  {
    path: '/raspored',
    name: 'raspored',
    component: Raspored
  },
  {
    path: '/treneri',
    name: 'treneri',
    component: Treneri
  }
]

const router = createRouter({
  routes,
  history: createWebHistory()
})

export default router
