<template>
    <div class="backdrop" @click.self="close">
        <div class="modal">
            <p style="font-size: 25px; font-weight: 600;">Prijave nisu u toku</p>
        </div>
    </div>
</template>

<script>
export default{
    methods: {
        close() {
            this.$emit('closeModal')
        }  
}
}
    
</script>


<style>
.modal {
    width: 300px;
    padding: 20px;
    margin: 40vh auto;
    background: white;
    border-radius: 10px;
}

.backdrop {
    top: 0;
    left: 0;
    position: fixed;
    background: rgba(0,0,0,0.5);
    width: 100%;
    height: 100%;
}

</style>