<template>
    <div class="hero">
        <div class="container">
            <div class="trener">
                <img class="img-left" :src="imgStasa" alt="">
                <p class="ime">Staša Lopušina</p>
                <p class="biografija"> Staša je Master psiholog i poslednje tri godine radi u HRu, u oblasti Talent Acqusition-a. Uključena je u procese regrutacije i selekcije, realizaciju programa praksi za mlade, kao i u aktivnosti koje su usmerene na studente. Inspiriše je rad sa mladima i imala je priliku da kreira i sprovodi radionice koje imaju za cilj osnaživanje studenata i podršku pri pravljenju prvih koraka u karijeri. Dodatno, edukuje se u oblasti koučing psihologije i interesuje je primena psiholoških koncepata u biznis okruženju.</p>
            </div>


            <div class="trener">
                <img class="img-right" :src="imgStevanija" alt="">
                <p class="ime">Stevanija Matejić Ivanović</p>
                <p class="biografija">Kao psiholog rada, profesionalno i privatno pomaže ljudima da pronađu i izgrade svoju karijeru. Smatra da je pravi izbor karijere vrlo bitan, s obzirom na to da je vreme provedeno u radu jedan od najvažnijih faktora koji oblikuje celokupnost jednog života. Trenutno radi kao Talent Acquisition Specialist u Telco industriji i veruje da je cilj HR profesionalaca da omoguće dovođenje pravih ljudi na prava radna mesta za njih i ostvarenje potencijala zaposlenih.</p>
            </div>


            <div class="trener">
                <img class="img-left" :src="imgDunja" alt="">
                <p class="ime">Dunja Đorđević</p>
                <p class="biografija">"Moje ime je Dunja, po struci sam diplomirani psiholog, po opredeljenju trener, a u duši kreativac. Nedavno sam uplovila u vode Ljudskih resursa i to baš u oblasti treninga i razvoja. Rado delim znanja i iskustva sa mladim ljudima, koja im mogu značiti u svim aspektima života. U slobodno vreme volim da čitam i uživam u dugim šetnjama."</p>
            </div>


            <div class="trener">
                <img class="img-right" :src="imgSimona" alt="">
                <p class="ime">Simona Šutić</p>
                <p class="biografija">Simona je završila master studije Elektrotehničkog fakulteta, na smeru Signali i Sistemi. U toku svojih stidentskih dana bila je aktivni član studentske organizacije Istek Lokalni Komitet Beograd, gde je 2019. godine imala priliku i da postane trener mekih veština. Nakon završene obuke za trenera, Simona je održala preko 100 sati treninga studentima širom Evrope. Neke od omiljenih tema su joj timski rad, upravljanje projektima i komunikacione veštine. </p>
            </div>

            <div class="trener">
                <img class="img-left" :src="imgErmin" alt="">
                <p class="ime">Ermin Mujanović</p>
                <p class="biografija">"Pozdrav,
Moje ime je Ermin i student sam Fakulteta elektrotehnike u Tuzli na smjeru Telekomunikacije.
Moja karijera u aktivizmu je počela baš jednim soft skills treningom i idejom da želim i ja biti sa druge strane...sada kada je vrijeme za to došlo, vrijedno je spomenuti i ono što se desilo na putu postanka Soft Skills trenera.
Naime, svoju ESTEC karijeru sam počeo kao kontaks osoba u Tuzli, kao koordinator lokalnih timova, koordinator projekta te kao šlag na tortu kao predsjednik ogranka u Tuzli. Sada sam, već kao iskusniji član sam se fokusirao na internacionalni dio asocijacije, gdje sam trenutno Lider Trening Tima, koji je usko vezan sa mekim vještinama te treninzima i sesijama u globalu, pa samim tim moja ljubav za njima postaje sve veća, a najbolji način za jačanje ili buđenje iste jeste baš Soft Skills Academy...ko zna, možda baš i ti budeš ovaj put sa jedne...a uskoro i sa druge strane, vidimo se!"</p>
            </div>


            <div class="trener">
                <img class="img-right" :src="imgMarija" alt="">
                <p class="ime">Marija Milanović</p>
                <p class="biografija">Marija Milanović je master inženjer elektrotehnike i računarstva a trenutno radi kao product manadžer u robotici. Postala je trener u okviru Isteka 2019. godine i od tada je držala brojne treninge za studente i mlade profesionalce širom Evrope. Kako je na poslu u stalnom kontaktu sa inženjerima ali i drugim menadžerima, trenutno je najviše inspirišu teme vezane za komunikacione veštine, prezentacione veštine i liderstvo.</p>
            </div>

            <div class="trener">
                <img class="img-left" :src="imgNevena" alt="">
                <p class="ime">Nevena Tomić</p>
                <p class="biografija">"Ja sam Nevena Tomić, student 4. godine Elektrotehničkog fakultetu u Beogradu. Tokom studija, preuzela sam na sebe različite volonterske uloge, uključujući koordinisanje studentskih projekata i rad u Upravnom odboru lokalnog komiteta Udruženja studenata elektrotehnike Evrope. Ova iskustva su mi pomogla da prepoznam značaj mekih veština za izgradnju zdravih međuljudskih odnosa i postizanje ličnih i profesionalnih ciljeva. Kao trener mekih veština, trudim se da podržim sve u kontinualnom radu na sebi. Kroz interaktivne sesije se nadam da ću inspirisati druge da daju prioritet razvijanju korisnih veština koje će ih pripremiti za uspeh u raznim aspektima života."</p>
            </div>

            <div class="trener">
                <img class="img-right" :src="imgUros" alt="">
                <p class="ime">Uroš Savurdić</p>
                <p class="biografija">Uroš je EESTEC trener iz 13te generacije. U EESTECu je više od 4 godine gde je aktivno radio na mnogobrojnim projektima i u mnogobrojnim timovima. Kao najbitnije uloge u organizaciji za njegov lični i profesionalni razvoj navodi liderske pozicije i to kao glavni organizator Internacionalne Kulturne Razmene i vođa međunarodnog Projekta Regionalizacije. Njegovo iskustvo ga je kasnije opredelilo da se usavrši kao trener. Omiljene teme treninga su mu: emocionalna inteligencija, upravljanje i rešavanje sukoba, liderstvo i nenasilna komunikacija.</p>
            </div>




        </div>

    </div>

</template>

<script>
export default{

    data(){
        return{
            /*imgSimona: require("../assets/treneri/Simona.jpg"),
            imgErmin: require("../assets/treneri/Ermin.jpg"),
            imgMarija: require("../assets/treneri/Marija.jpg"),
            imgNevena: require("../assets/treneri/Nevena.jpg"),
            imgDunja: require("../assets/treneri/Dunja.jpg"),
            imgStasa: require("../assets/treneri/Stasa.jpg"),
            imgStevanija: require("../assets/treneri/Stevanija.jpg"),
            imgUros: require("../assets/treneri/Uros.jpg"),*/
        }
    }
}

</script>

<style scoped>
    .container{
        display: flex;
        justify-content: space-around;
        flex-wrap: wrap;
    }
    .hero{
        background-image: url("../assets/home_hero_bg.png");
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center center;
        background-attachment: fixed;
        height: 100%;
        left: 0;
        right: 0;
        font-size: 17px;        
        padding-top: 10%;
        padding-bottom: 10%;
    }
    .trener{
        border: 5px solid #11998E;
        height: 100%;
        width: 45%;
        background-color: #38EF7D;
        margin-bottom: 20px;
        border-radius: 20px;
        overflow: hidden;
    }
    @media(max-width: 768px){
        .hero{
            background: #11998e;
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center center;
            background-attachment: fixed;
            height: 100%;
            left: 0;
            right: 0;
            font-size: 17px;        
            padding-top: 10%;
            padding-bottom: 10%;
        }
        .trener{
            border: 5px solid white;
            height: 100%;
            width: 45%;
            background-color: #38EF7D;
            margin-bottom: 20px;
            border-radius: 20px;
            overflow: hidden;
        }
    }

    .trener{

        margin-top: 15px;
    }

    .trener .img-left{
        float: left;        
        margin-right: 20px;
        margin-bottom: 5px;
    }
    .trener .img-right{
        float: right;
        margin-left: 20px;
        margin-bottom: 5px;
    }
    .ime{
        font-size: 20px;
        font-weight: 700;
    }
    
    .biografija{
        text-align: justify;
        margin: 15px;
    }

@media (max-width: 991px) {
    .container{
        justify-content: center;
    }
    .trener{
        width: 80%;
        margin-top: 40px;
    }
}

@media (max-width: 768px) {
    .trener{
        margin-top: 0px;
        margin-bottom: 20px;
    }
}

@media (max-width: 479px) {

    .trener{
        width: min-content;
    }

    .trener .img-left{
        float: none;
    }
    .trener .img-right{
        float: none;
    }

    .trener img{
        margin: 10px;
        border-radius: 20px 20px 0px 0px;
    }

    .ime{
        margin: 0px;
        font-size: 15px;
    }

    .biografija{
        font-size: 12px;
    }
}
</style>